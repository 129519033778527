.nav {
  position: fixed;
  right: 0;
  left: 0;
}

.navcont {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  .toggler {
    display: none;
  }

  .navBody {
    transform: translate(0, 50%);
    position: relative;
    width: 90%;
    border-radius: 40px;
    @media screen and (max-width: 768px) {
      border-radius: 0px;
      transform: translate(0, 100%);
      transition: all .4s ease-in-out;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;

    }

    &.closed {
      transform: translate(-150%, 100%);
    }

  }

  .contacts {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: fit-content;
    width: 100%;

    a {
      button {
        color: #033053;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        font-size: 15px;
        background-color: transparent;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      font-size: 15px;
      a {
        button {

          font-size: 12px;
        }
      }
    }
    @media screen and (max-width: 457px) {
      flex-direction: row;
      align-items: flex-end;
      font-size: 18px;
      width: 50%;
      a {
        margin: 0;
        padding: 0;
        width: 50%;

        button {

          font-size: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    .toggler {
      display: block;
      padding: .5em;
      background-color: transparent;
      color: rgb(0, 0, 0);
      margin: .5em;
      margin-left: auto;

    }
  }
}

.navlist {
  width: 100%;
  display: flex;
  flex-direction: row;

  > .navlist-inner {
    width: 100%;
    height: fit-content;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    li {
      margin: 0;
      padding: 0;
      width: 100%;
      overflow: hidden;
      background-color: #033053;

      color: white;

      :hover {
        background-color: white;
        color: #033053;
      }

      a {
        p {
          background-color: #033053;
          height: 100%;
          margin: auto;
          padding: .8em;
        }
      }

      .navlist-sub {
        display: none;
      }

    }

    > li:first-child {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
    }

    > li:last-child {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;

    }

    li.sub-list {
      .navlist-sub {
        color: whitesmoke;
      }

      &:hover {
        .navlist-sub {
          display: block;
          background-color: rgba(210, 210, 210, 0.68);
          color: #033053;
          position: absolute;

          a {
            p {
              background-color: #ffffff00;
              height: 100%;
              margin: auto;
              padding: .1em;

            }


          }

          li {
            padding: .9em;
          }

          @media screen and (max-width: 768px) {
            position: relative;

          }
        }

      }
    }

    @media screen and (max-width: 768px) {
      height: fit-content;
      flex-direction: column;
      padding: .5em;;
      align-items: center;
      > li:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;


      }
      > li:last-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }


  }

  @media screen and (max-width: 768px) {
    height: 100vh;
  }

}


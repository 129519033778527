.landing-page-services {
  font-family: "Roboto Condensed", serif;
  position: relative;
  padding:0;
  height: 100%;
  background-color: rgba(246, 246, 246, 0.4);
  backdrop-filter: blur(2px);
  .landing-page-services-heading {
    background-color: #033053;
    color: white;
    padding:.5em;
  }
  .landing-page-services-body{
    color: white;
    height: fit-content;
  }
  &:hover {
      transition: all .1s ease;
      background-color: rgb(255, 255, 255);
      color: black;
    .landing-page-services-body{
      color: rgba(0, 0, 0, 0.82);


    }

  }
}





@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/scss/image-gallery.scss";

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #033053;
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
}

.App {
  text-align: center;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  height: 100%;
}

.videoDisplay {
  pointer-events: none;

  &-webkit-media-controls-panel {
    display: none;
    opacity: 1;
  }
}
